import {
  createRouter,
  createWebHistory
} from 'vue-router'


const routes = [{
    path: '/',
    redirect: '/index'
    // redirect: '/NewsCenter'
  },
  {
    path: '/index',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/index/index')
  },
  {
    path: '/Bidding',
    meta: {
      title: '招标信息'
    },
    component: () => import('@/views/Bidding/Bidding')
  },
  {
    path: '/Purchasing',
    meta: {
      title: '采购信息'
    },
    component: () => import('@/views/Purchasing/Purchasing')
  },
  {
    path: '/Financial',
    meta: {
      title: '金融服务'
    },
    component: () => import('@/views/Financial/Financial')
  },
  {
    path: '/NewsCenter',
    meta: {
      title: '新闻中心'
    },
    component: () => import('@/views/NewsCenter/NewsCenter')
  },
  {
    path: '/Member',
    meta: {
      title: '会员权益'
    },
    component: () => import('@/views/Member/Member')
  },
  {
    path: '/Contact',
    meta: {
      title: '联系我们'
    },
    component: () => import('@/views/Contact/Contact')
  },
  {
    path: '/Equity',
    meta: {
      title: '会员权益'
    },
    component: () => import('@/views/Equity/Equity')
  },
  {
    path: '/BiddingInfo',
    meta: {
      title: '招标信息详情'
    },
    component: () => import('@/views/BiddingInfo/BiddingInfo')
  },
  {
    path: '/ListOfNews',
    meta: {
      title: '新闻列表页'
    },
    component: () => import('@/views/ListOfNews/ListOfNews')
  },
  {
    path: '/InfoView',
    meta: {
      title: '咨询详情'
    },
    component: () => import('@/views/InfoView/InfoView')
  },
  {
    path: '/CaApply',
    meta: {
      title: 'CA办理'
    },
    component: () => import('@/views/Ca/CaApply')
  },
  {
    path: '/secondaryHead',
    meta: {
      title: '二级详情'
    },
    component: () => import('@/views/secondaryHead/secondaryHead.vue')
  },
  {
    path: '/BiddingAuction',
    meta: {
      title: '招采拍卖'
    },
    component: () => import('@/views/BiddingAuction/BiddingAuction.vue')
  },
  {
    path: '/BusinessPerformance',
    meta: {
      title: '经营业绩'
    },
    component: () => import('@/views/BusinessPerformance/BusinessPerformance.vue')
  }, {
    path: '/YdTender',
    meta: {
      title: '英大招标'
    },
    component: () => import('@/views/YdTender/YdTender.vue')
  }, {
    path: '/YdPawn',
    meta: {
      title: '英大拍卖'
    },
    component: () => import('@/views/YdPawn/YdPawn.vue')
  }, {
    path: '/YdAuction',
    meta: {
      title: '英大典当'
    },
    component: () => import('@/views/YdAuction/YdAuction.vue')
  }, {
    path: '/YdMore',
    meta: {
      title: '英大更多'
    },
    component: () => import('@/views/YdMore/YdMore.vue')
  }, {
    path: '/AuctionDetails',
    meta: {
      title: '拍卖详情'
    },
    component: () => import('@/views/AuctionDetails/AuctionDetails.vue')
  }, {
    path: '/Listdetails',
    meta: {
      title: '列表详情'
    },
    component: () => import('@/views/Listdetails/ListDetails.vue')
  }, {
    path: '/LIST',
    meta: {
      title: '共用列表'
    },
    component: () => import('@/views/SecondaryLists/SecondaryLists.vue')
  }, {
    path: '/DETAILS',
    meta: {
      title: '共用详情'
    },
    component: () => import('@/views/ThreeDetails/ThreeDetails.vue')
  }
]


const router = createRouter({
  routes,
  history: createWebHistory()
})


router.beforeEach((to, from, next) => {
  // if (to.meta.title) {
  //     document.title = to.meta.title
  // }
  document.title = "英大招投标集团"
  document.documentElement.scrollTop = 0
  next()
})

export default router
