// http://192.168.0.62:8077/api/fontController/fontController/
import request from "@/core/request"

const getAllNav = () => {
  return request.request({
    url: '/api/fontController/fontController/getAllNav',
    method: 'GET'
  })
}

const getAllNavList = () => {
  return request.request({
    url: '/api/fontController/fontController/getAllNavList',
    method: 'GET'
  })
}

const getAllAd = () => {
  return request.request({
    url: '/api/fontController/fontController/getAllAd',
    method: 'GET'
  })
}

const getAllArticle = (params) => {
  return request.request({
    url: '/api/fontController/fontController/getAllArticle',
    method: 'GET',
    params
  })
}

const getArticleDetailsById = (params) => {
  return request.request({
    url: '/api/fontController/fontController/getArticleDetails',
    method: 'GET',
    params
  })
}


const getStatistics = () => {
  return request.request({
    url: '/api/portal-web/statistics',
    method: 'GET',
  })
}

const getAllNotice = (params) => {
  // const url = `/api/portal-web/bulletin?size=${params.size}&type=${params.type[0]}${params.type.length>=2?`&type=${params.type[1]}`:''}`
  const url = `/api/portal-web/bulletin?size=${params.size}&tradeClassification=${params.tradeClassification}`
  // tradeClassification
  return request.request({
    url: url,
    method: 'GET',
  })

}

// const getImgCode = () => {
//     return request.request({
//         url: 'api/blade-auth/oauth/captcha',
//         method: 'GET'
//     })
// }


const getImgCode = () => {
  return request.request({
    url: 'api/blade-auth/oauth/captchaFont',
    method: 'GET'
  })
}
const submitUserInfo = (data) => {
  return request.request({
    url: '/api/fontController/fontController/saveTicket',
    method: 'POST',
    data
  })
}

const getTableList = (params) => {
  const url =
    `/api/portal-web/bulletin/list?tradeClassification=${params.tradeClassification?params.tradeClassification:''}&current=${params.current}&size=${params.size}&type=${params.type}&descs=${params.descs}&tenderProjectClassifyCodeFirst=${params.tenderProjectClassifyCodeFirst?params.tenderProjectClassifyCodeFirst[0]:''}${params.tenderProjectClassifyCodeFirst?params.tenderProjectClassifyCodeFirst.length>=2?`&tenderProjectClassifyCodeFirst=${params.tenderProjectClassifyCodeFirst[1]}`:'':''}&tenderProjectClassifyCodeSecond=${params.tenderProjectClassifyCodeSecond?params.tenderProjectClassifyCodeSecond:''}&ascs=${params.ascs}&name=${params.name}`
  return request.request({
    url: url,
    method: "GET",
  })
}

const getClassfiyCode = (params) => {
  let dictKeyStr = ''
  for (let i in params.dictKey) {
    dictKeyStr += '&dictKey=' + params.dictKey[i]
  }
  // (dictKeyStr, 'dictKeyStr')
  const url =
    `/api/portal-web/tenderProjectClassifyCode/list?dictKey=${params.dictKey[0]}${params.dictKey.length>=2?`&dictKey=${params.dictKey[1]}`:''}${params.dictKey.length>=3?`&dictKey=${params.dictKey[2]}`:''}`
  return request.request({
    url: url,
    method: "GET",
  })
}

const getNewsTable = (params) => {
  return request.request({
    url: '/api/fontController/fontController/getAllArticlePage',
    method: 'GET',
    params
  })
}

const getNoticeById = (params) => {
  return request.request({
    url: "/api/portal-web/bulletin/details",
    method: "GET",
    params
  })
}

const listFindTenderBulletin = (params) => {
  return request.request({
    url: '/sino/api/cms/tenderBulletin/listFindTenderBulletin',
    method: 'POST',
    params: {
      ...params
    }
  })
}
const findTenderBulletinType = () => {
  return request.request({
    url: '/sino/api/cms/tenderBulletin/findTenderBulletinType',
    method: 'POST'
  })
}
const getByIdAndBulletinType = (data) => {
  return request.request({
    url: '/sino/api/cms/tenderBulletin/getByIdAndBulletinType',
    method: 'POST',
    params: {
      ...data
    }
  })
}
const findGpTenderWay = (data) => {
  return request.request({
    url: '/sino/api/cms/tenderBulletin/findGpTenderWay',
    method: 'POST',
    params: {
      ...data
    }
  })
}
const findByAzcId = (data) => {
  return request.request({
    url: '/sino/api/cms/tenderBulletin/findByAzcId?azcId=113239029805260800',
    method: 'post',
    params: data
  })
}
const findByAtiId = (data) => {
  return request.request({
    url: '/sino/api/cms/tenderBulletin/findByAtiId',
    method: 'post',
    params: data
  })
}
const findRootToLevel = (data) => {
  return request.request({
    url: '/sino/api/cms/tenderBulletin/findRootToLevel',
    method: 'post',
    params: data
  })
}
const getFlagValue = (data) => {
  return request.request({
    url: '/sino/api/cms/tenderBulletin/getFlagValue',
    method: 'post',
    params: {
      ...data
    }
  })
}
const getCmsList = (current, size, params) => {
  return request.request({
    url: '/apiI/sino-biz/auctionBulletin/cmsList',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}
const getDetail = (id) => {
  return request.request({
    url: '/apiI/sino-biz/auctionBulletin/detail',
    method: 'get',
    params: {
      id
    }
  })
}
const getBizDictByCode = (params) => {
  return request.request({
    url: '/api/blade-system/dict/dictionary?code=' + params,
    method: 'get'
  })
}
export {
  getBizDictByCode,
  getAllAd,
  getAllNav,
  getAllNavList,
  getAllArticle,
  getStatistics,
  getAllNotice,
  submitUserInfo,
  getImgCode,
  getTableList,
  getClassfiyCode,
  getNewsTable,
  getNoticeById,
  getArticleDetailsById,
  listFindTenderBulletin,
  findTenderBulletinType,
  getByIdAndBulletinType,
  findGpTenderWay,
  findByAzcId,
  findByAtiId,
  findRootToLevel,
  getFlagValue,
  getCmsList,
  getDetail
}
