import request from "@/util/axios"

const getWebTitle = () => {
  return request.request({
    url: "/api/site/cmssite/getWebTitle",
    method: "GET",
    params: {}
  })
}

const getWebData = () => {
  return request.request({
    url: "/api/site/cmssite/getSite",
    method: "GET",
    params: {}
  })
}

const listLeftNav = () => {
  return request.request({
    url: "/api/leftnav/contentleftnav/listLeftNav",
    method: "GET",
    params: {}
  })
}

export {
  getWebTitle,
  getWebData,
  listLeftNav,
}
