<template>
  <router-view></router-view>
</template>

<script>
import {
  mainStore,
  BiddingTableStore,
  PurchasingTableStore,
  policyModularStore,
} from "@/store/index";
import { storeToRefs } from "pinia";
import { onBeforeMount } from "vue";

export default {
  setup() {
    onBeforeMount(async () => {
      const store = mainStore();
      await store.initState();
      await BiddingTableStore().getClassfiyData();
      await PurchasingTableStore().getClassfiyData();
      await policyModularStore().setData(
        "data",
        storeToRefs(store).PolicyInfo.value
      );
      await policyModularStore().reloadData();
    });
    const store = mainStore();
    const {
      navBarGetter,
      categoryNavGetter,
      AdDataGetter,
      ActiveArticleGetter,
    } = storeToRefs(store);
    return {
      navBarGetter,
      categoryNavGetter,
      AdDataGetter,
      ActiveArticleGetter,
    };
  },
};
</script>

<style>
* {
  font-family: "CN-Regular";
}

.el-table tr {
  height: 80px !important;
}

.el-carousel__button {
  height: 8px !important;
  width: 8px !important;
  border-radius: 100% !important;
}
</style>

