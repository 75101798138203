import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { getToken } from "@/util/index";
import { ElMessageBox } from "element-plus";
import { checkUser } from '@/api/caApply/caApply';
import { getCurrentMember } from "@/api/Member/Member"


const stateStore = defineStore('member', {
  state: () => {
    const data = reactive({
      currentMember: {},
      payMemberVisible: false,
      payResultVisible: false,
      benefitTypeList: [],
      memberList: [],
      money: null,
      payType: null,
      payTime: '',
      orderNo: '',

      timer: '',
    })
    return data
  },
  actions: {
    async setData(type, value) {
      switch (type) {
        case 'currentMember':
          this.currentMember = value;
          break;
        case 'benefitTypeList':
          this.benefitTypeList = value;
          break;
        case 'memberList':
          this.memberList = value;
          break;
        case 'money':
          this.money = value
          break
        case 'payType':
          this.payType = value
          break
        case 'payTime':
          this.payTime = value
          break
        case 'orderNo':
          this.orderNo = value
          break
        case 'timer':
          this.timer = value
          break
      }
    },
    async openPayMember() {
      this.payResultVisible = false;
      if (getToken() === null) {
        ElMessageBox.confirm(
          "您还未登录，是否跳转到登录页面？",
        ).then(() => {
          window.open(process.env.VUE_APP_SEVERCE_PLATFORM, "_blank");
        });
      } else {
        const result = await checkUser()
        if (result.data) {
          const res = await getCurrentMember();
          if (res.data) {
            this.currentMember = res.data
          }
          this.payMemberVisible = true;
        } else {
          ElMessageBox.confirm(
            "您还未完善信息，是否跳转到信息填写页面？",
          ).then(() => {
            window.open(process.env.VUE_APP_SEVERCE_PLATFORM + '/#/perfectInfo', "_blank");
          });
        }
      }
    },
    async toPayResult() {
      this.payMemberVisible = false;
      this.payResultVisible = true;
    }
  }

})


export default stateStore