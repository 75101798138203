import {
  defineStore
} from 'pinia'
import {
  getToken
} from "@/util/index";
import {
  getImgCode
} from "@/api/index";
import {
  ElMessageBox
} from "element-plus";

import {
  checkUser
} from '@/api/caApply/caApply'

export const caApplyStore = defineStore('caApply', {
  state: () => {
    return {
      viewModel: 0,
      applyGuide: {},
      imgCode: {
        image: "",
        key: "",
        code: ""
      },
      applyId: null,
      amount: "",
      dialogVisible: false,
      payType: 'wxpay',
      orderNo: '',
      codeUrl: '',
      timer: ''
    }
  },
  actions: {
    setData(type, value) {
      switch (type) {
        case 'applyId':
          this.applyId = value
          break
        case 'amount':
          this.amount = value
          break
        case 'dialogVisible':
          this.dialogVisible = value
          break
        case 'payType':
          this.payType = value
          break
        case 'orderNo':
          this.orderNo = value
          break
        case 'codeUrl':
          this.codeUrl = value
          break
        case 'timer':
          this.timer = value
          break
      }
    },
    async changeView(view) {
      if (getToken() === null) {
        ElMessageBox.confirm(
          "您还未登录，是否跳转到登录页面？",
        ).then(() => {
          window.open(process.env.VUE_APP_SEVERCE_PLATFORM, "_blank");
        });
      } else {
        const result = await checkUser()
        if (result.data) {
          this.viewModel = view;
        } else {
          ElMessageBox.confirm(
            "您还未完善信息，是否跳转到信息填写页面？",
          ).then(() => {
            window.open(process.env.VUE_APP_SEVERCE_PLATFORM + '/#/perfectInfo', "_blank");
          });
        }
      }
    },
    async setapplyGuide(value) {
      this.applyGuide = value
    },
    async loadImgCode() {
      const res = await getImgCode();
      this.imgCode.image = res.image;
      this.imgCode.key = res.key;
      this.imgCode.code = res.code;
    },
  },
});

export default caApplyStore;