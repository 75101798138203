import {
  defineStore
} from 'pinia'
import {
  getAllNav,
  getAllArticle,
  getAllAd,
  getStatistics,
  getAllNotice,
  getImgCode,
  getTableList,
  getAllNavList,
  getNewsTable
} from "@/api/index";
import {
  reactive
} from 'vue'
import imgData from "@/data";
import { getWebData, listLeftNav, } from '@/api/cmsSite/cmsSite';
const mainStore = defineStore('main', {
  state: () => {
    const data = reactive({
      // 网站页面信息
      webData: [],
      // 左侧导航栏信息
      leftNav: [],
      AllNavData: [],
      helpAllData: [],
      navBar: [],
      categoryNav: [],
      AdData: [],
      AdData2: [],
      AdData3: [],
      DlogAd: [],
      Article: {},
      Statistics: [],
      activePageUrl: '',
      NoticeOfServiceAndGoods: [],
      NoticeOfEngineering: [],
      NoticeOfEngineeringTableData: [],
      NoticeOfServiceAndGoodsTableData: [],
      activeImg: '',
      activeImgKey: '',
      activeImgCode: '',
      PurchasingTableData: [],
      BiddingTableData: [],
      newsAllData: [],
      newsLeftData: [],
      newsTopData: [],
      newsBottomData: [],
      PolicyInfo: [],
      auditSpecification: [],
      PolicyRule: [],
      CentralizedPurch: [],
      AllPolicyData: [],
      CompanyProfile: '',
      ContactHtml: '',
      AllArticle: [],
      news_ActiveId: '',
      news_ActiveType: '',
      news_currentPage: 1,
      news_size: 10,
      news_total: null,
      news_data: [],
      newsTypeData: [],
      mainBusiness: [],
      classicPerformance: [],
      YdTender: [],
      YdPawn: [],
      YdAuction: [],
      YdEngineering: [],
      YdManagement: []
      // caApplyCode: {
      //     key: "",
      //     image: "",
      //     code: ""
      // }

    })
    return {
      ...data,
      navData: {},
    }
  },
  getters: {
    // webData: state => state.bannerNews,
    navBarGetter: state => state.navBar,
    categoryNavGetter: state => state.categoryNav,
    AdDataGetter: state => state.AdData,
    ArticleGetter: state => state.Article,
    StatisticsGetter: state => state.Statistics,
    activePageUrlGetter: state => state.activePageUrl,
    NoticeOfServiceAndGoodsGetter: state => state.NoticeOfServiceAndGoods,
    NoticeOfEngineeringGetter: state => state.NoticeOfEngineering,
  },
  actions: {
    async changeBannerUrl(value) {
      console.log("切换栏目", value)
      // this.navData = value.banner.link;
      if (value.banner && value.banner.link) {
        this.navData = value.banner.link;
      }
    },

    // 新写
    async getWebData() {
      const data = await getWebData()
      this.webData = data.data;
    },

    async listLeftNav() {
      const data = await listLeftNav()
      this.leftNav = data.data;
    },


    async initState() {
      await this.getNavData()
      await this.getAdData()
      await this.getStatistics()
      await this.getNotice()
      await this.setactiveImgCodeInfo()
      await this.getAllNavList()
      await this.listLeftNav()
      await this.changeBannerUrl(this.navBar[0])
    },
    async getAllNavList() {
      const data = await getAllNavList()
      this.navBar = data.data
    },
    async setactiveImgCodeInfo() {
      const data = await getImgCode()
      this.activeImg = data.image
      this.activeImgKey = data.key
      this.activeImgCode = data.code
    },
    async getNavData() {
      const data = await getAllNav()
      let navBarData = []
      let categoryNavData = []
      let AllNavData = []
      data.data.map(v => {
        if (v.isNavigation == 1) {
          navBarData.push(v)
          AllNavData.push(v)
          this.getArticle({
            id: v.id
          }, v.categoryName)
        } else {
          categoryNavData.push(v)
          AllNavData.push(v)
          this.getArticle({
            id: v.id
          }, v.categoryName)
        }
      })
      this.categoryNav = categoryNavData
      // this.navBar = navBarData
      this.AllNavData = AllNavData
      this.AllNavData.map(v => {
        if (v.categoryName == '政策资讯') {
          this.newsTypeData.push({
            title: v.categoryName,
            id: v.id
          })
        } else if (v.categoryName == '新闻中心') {
          this.newsTypeData.push({
            title: v.categoryName,
            id: v.id
          })
        }
      })
    },
    async getAdData() {
      const data = await getAllAd()
      console.log(data.data, 'data');
      data.data.map(v => {
        if (v.adType == 0) {
          this.DlogAd.push(v)

        } else if (v.adType == 1 || v.adType == null) {
          this.AdData.push(v)
        } else if (v.adType == 2) {
          this.AdData2.push(v)
        } else if (v.adType == 3) {
          this.AdData3.push(v)
        }
      })
      this.DlogAd = this.DlogAd ? this.DlogAd[0] : ''
      // {
      //     adTitle: '广告位招租',
      //     attachList: []
      // }

      // this.DlogAd = data.data
      // this.AdData = data.data
    },
    async getArticle(params, key) {
      const data = await getAllArticle(params)
      if (key == '视频操作教学') {
        this.Article[key] = data.data.map(v => {
          if (v.attachList.length > 0) {
            // const attachListArr = v.attachList.map(av => ({
            //     link: av.link.replace('https', 'http')
            // }))
            // v.attachList = attachListArr
            // const obj = Object.assign(v, {
            //     attachList: v.attachList.map(av => {
            //         return Object.assign(av, {
            //             link: av.link.replace('https', 'http')
            //         })
            //     })
            // })
            return v
          } else {
            return v
          }
        })
      } else {
        this.Article[key] = data.data
      }
      if (key == '集团要闻') {
        this.newsLeftData = data.data.slice(0, 1)
        this.newsTopData = data.data.slice(1, 3)
        this.newsBottomData = data.data.slice(3, 5)
        this.newsAllData = data.data.slice(0, 4)
      } else if (key == '政策资讯') {
        this.PolicyInfo = data.data
      } else if (key == '供应商审核规范') {
        this.auditSpecification = data.data.slice(0, 1)
        if (this.auditSpecification.length > 0) {
          this.auditSpecification[0].categoryName = '供应商审核规范'
        }
        this.AllPolicyData = [...this.AllPolicyData, ...this.auditSpecification]
      } else if (key == '政策法规') {
        this.PolicyRule = data.data.slice(0, 4)
        if (this.PolicyRule.length > 0) {
          this.PolicyRule[0].categoryName = '政策法规'
        }
        this.AllPolicyData = [...this.AllPolicyData, ...this.PolicyRule]
      } else if (key == '集采新闻') {
        this.CentralizedPurch = data.data.slice(0, 1)
        if (this.CentralizedPurch.length > 0) {
          this.CentralizedPurch[0].categoryName = '集采新闻'
        }
        this.AllPolicyData = [...this.AllPolicyData, ...this.CentralizedPurch]
      } else if (key == '公司简介') {
        if (data.data.slice(0, 1).length > 0) {
          this.CompanyProfile = data.data[0].content
        }
      } else if (key == '联系我们') {
        if (data.data.slice(0, 1).length > 0) {
          this.ContactHtml = data.data[0].content
        }
      } else if (key == '帮助中心') {
        this.helpAllData = data.data.slice(0, 5)
      } else if (key == '主营业务') {
        this.mainBusiness = data.data.slice(0, 13)
      } else if (key == '经营业绩') {
        this.classicPerformance = data.data.slice(0, 8)
      } else if (key == '英大招标') {
        this.YdTender = data.data.slice(0, 1)
      } else if (key == '英大拍卖') {
        this.YdPawn = data.data.slice(0, 1)
      } else if (key == '英大典当') {
        this.YdAuction = data.data.slice(0, 1)
      } else if (key == '英大工程') {
        this.YdEngineering = data.data.slice(0, 1)
      } else if (key == '大禹管理') {
        this.YdManagement = data.data.slice(0, 1)
      }
    },
    async getStatistics() {
      const result = await getStatistics()
      const keys = Object.keys(result)
      for (let key in keys) {
        // if (keys[key] == 'accumulated_transaction_amount') {
        //     console.log(result[keys[key]], 'result[keys[key]]')
        //     console.log(result[keys[key]].replace(',', ''))
        // }
        this.Statistics.push({
          label: keys[key] == 'accumulated_transaction_amount' ? '累计项目金额' : keys[key] == 'company_num' ? '入驻数量' : keys[key] == "project_num" ? '工程项目' : keys[key] == 'purchase_quantity' ?
            '采购项目' : keys[key] == 'transaction_volume' ? '待开标数量' : '',
          num: keys[key] == 'accumulated_transaction_amount' ? Number(result[keys[key]].replaceAll(',', '')) : Number(result[keys[key]]),
          sortIndex: keys[key] == 'accumulated_transaction_amount' ? 5 : keys[key] == 'company_num' ? 1 : keys[key] == "project_num" ? 2 : keys[key] == 'purchase_quantity' ? 3 : keys[key] ==
            'transaction_volume' ? 4 : null
        })
      }
      this.Statistics.sort((a, b) => a.sortIndex - b.sortIndex)
    },
    async getNotice() {
      const ServicesAndGoods = await getAllNotice({
        size: 12,
        tradeClassification: 'D'
      })
      const Engineering = await getAllNotice({
        size: 12,
        tradeClassification: 'A'
      })
      let key = 0
      let keys = Object.keys(Engineering)
      // const imgKeyArr = ['Item1', 'Item2', 'Item3', 'Item4']
      // for (key in keys) {
      //   this.NoticeOfEngineeringTableData = [...this.NoticeOfEngineeringTableData, ...Engineering[keys[key]]]
      //   this.NoticeOfEngineering[keys[key]] = Engineering[keys[key]].map((v, index) => ({
      //     id: v.id,
      //     title: v.secondLevelDictName.length > 4 ? v.secondLevelDictName.slice(0, 4) + '...' : v.secondLevelDictName,
      //     // title: this.CheckEngineerTitle(index),
      //     bulletinCode: v.bulletinCode,
      //     tenderProjectClassifyCode: v.tenderProjectClassifyCode,
      //     subTitle: v.name,
      //     content: v.content,
      //     price: v.contractReckonPrice,
      //     status: v.state == -1 ? "进行中" : "已结束",
      //     endtime: v.endTime,
      //     publishTime: v.publishTime, //发布时间
      //     showRight: v.content ? true : false,
      //     htmlContent: v.content,
      //     // url: imgData.EngineeringImg[imgKeyArr[Math.floor(Math.random() * imgKeyArr.length)]],
      //     url: this.checkStr(v.secondLevelDictName),
      //     // url: this.CheckEngineerImg(index),
      //     isEnd: v.isEnd,
      //     contractRecksonPriceUnit: v.contractRecksonPriceUnit,
      //   }))
      // }
      key = 0
      keys = Object.keys(ServicesAndGoods)
      for (key in keys) {
        this.NoticeOfServiceAndGoodsTableData = [...this.NoticeOfServiceAndGoodsTableData, ...ServicesAndGoods[keys[key]]]
        this.NoticeOfServiceAndGoods[keys[key]] = ServicesAndGoods[keys[key]].map(v => ({
          id: v.id,
          title: v.secondLevelDictName.length > 4 ? v.secondLevelDictName.slice(0, 4) + '...' : v.secondLevelDictName,
          bulletinCode: v.bulletinCode,
          tenderProjectClassifyCode: v.tenderProjectClassifyCode,
          subTitle: v.name,
          content: v.content,
          price: v.contractReckonPrice,
          status: v.state == -1 ? "进行中" : "已结束",
          endtime: v.endTime,
          showRight: v.content ? true : false,
          htmlContent: v.content,
          publishTime: v.publishTime,
          // url: imgData.PurchasingImg[imgKeyArr[Math.floor(Math.random() * imgKeyArr.length)]],
          url: this.checkStr(v.secondLevelDictName),
          isEnd: v.isEnd,
          contractRecksonPriceUnit: v.contractRecksonPriceUnit
        }))
      }
    },
    async setactivePageUrl(url) {
      this.activePageUrl = url
    },
    async setTableData(params, type) {
      if (type == 1) {
        this.PurchasingTableData = await getTableList(params)
      } else {
        this.BiddingTableData = await getTableList(params)
      }

    },
    async setData(type, value) {
      switch (type) {
        case 'news_ActiveType':
          if (value == 'news') {
            const obj = this.newsTypeData.find(v => v.title == '新闻中心')
            this.news_ActiveId = obj.id
          } else if (value == 'policy') {
            this.news_ActiveId = this.newsTypeData.find(v => v.title == '政策资讯').id
          }
          this.news_ActiveType = value
          break
        case 'news_ActiveId':
          this.news_ActiveId = value
          break
        case 'news_currentPage':
          this.news_currentPage = value
          break
        case 'news_size':
          this.news_size = value
          break
        case 'news_total':
          this.news_total = value
          break
        case 'news_data':
          this.news_data = value
          break
        default:
          break
      }
    },
    async newsReloadTableData() {
      const res = await getNewsTable({
        current: this.news_currentPage,
        size: this.news_size,
        // id: this.activeId
        id: this.news_ActiveId
      });
      this.setData("news_total", res.data.total);
      this.setData("news_data", res.data.records);
    },
    CheckEngineerImg(value) {
      const {
        categoryImg: v
      } = imgData
      const type = value % 4
      return type == 0 ? v.road : type == 1 ? v.Landscaping : type == 2 ? v.Landscaping2 : v.Bridge
    },
    CheckEngineerTitle(value) {
      const type = value % 4
      return type == 0 ? '道路工程' : type == 1 ? '园林绿化' : type == 2 ? '园林绿化' : '桥梁工程'
    },
    checkStr(value) {
      const {
        categoryImg: v
      } = imgData
      switch (value) {
        case '招标代理服务':
          return v.biddingAgency
        case '新闻中心':
          return v.news
        case '设计':
          return v.design
        case '其他服务':
          return v.other
        case '勘查与调查':
          return v.investigation
        case '勘察':
          return v.survey
        case '建筑材料':
          return v.material
        case '监理':
          return v.supervise
        case '工商管理':
          return v.business
        case '工程类公告':
          return v.engineering
        case '规划':
          return v.plan
        case '工程施工':
          return v.construction
        case '医疗器械':
          return v.medical
        case '石油及其制品':
          return v.petroleum
        case '园林绿化':
          return v.Landscaping
        case '道路工程':
          return v.road
        case '桥梁工程':
          return v.Bridge
        case '化工材料及其制品':
          return v.Chemical
        case '修理':
          return v.Repair
        case '机械、设备类':
          return v.Mechanical
        default:
          return v.other
      }
    },
    checkTitle(id) {
      let keys, data, key;
      keys = Object.keys(this.Article)
      for (let index in keys) {
        key = keys[index]
        data = JSON.stringify(this.Article[key])
        if (data.indexOf(id) > -1) {
          return key
        }
      }
    }

  }
})


export default mainStore;
